.overflow__margin {
  margin-top: 20px;
}
.older__doc___table > td {
  text-align: center;
}

.older__doc___table > .numeric__right {
  text-align: right;
  padding-right: 48px;
}
