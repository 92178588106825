.NAVLOG_HEADING {
  display: flex;
  justify-content: center;
}
.section1__navlog {
  text-transform: uppercase;
  width: 100%;
  table-layout: auto;
  margin: 10px 0px;
}
.table1__navlog,
.main__flight__section {
  background-color: #ededed !important;
}
.section1__navlog,
.section1__navlog > tr > th,
.section1__navlog > tr > td,
.section1__navlog > tr {
  border: 1px solid black;
  border-collapse: collapse;
}
.section1__navlog > tr > td > input {
  border: none;
  width: 100px;
}

.route__plan,
.flight__section > tr > td > p {
  font-size: 14px;
  font-weight: 400;
}
/* 
.flight__section > tr > td > .custom-input[readOnly] {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
} */

.route__plan {
  text-transform: uppercase;
  width: 100%;
  table-layout: auto;
}
.route__plan > tr > th {
  color: rgb(0, 0, 0);
  font-size: small;
  font-weight: 500;
  height: 35px;
  border: 1px solid black;
  background-color: rgb(171, 240, 255);
}
.route__plan__head {
  text-align: start;
}
.text__special__instruction {
  margin-top: 10px;
  margin-left: 1%;
  width: 98%;
  display: flex;
  justify-content: space-between;
}
.route__plan > tr > td > input {
  max-width: 100px;
}
.flight__section {
  text-transform: uppercase;
  width: 100%;
  table-layout: auto;
}
.flight__section > tr > td > input {
  max-width: 60px;
}
.flight__section > tr > th {
  border: 1px solid black;
  font-size: small;
  max-width: 60px;
  height: 40px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  background-color: rgb(171, 240, 255);
}
.text__Area__captain__sign {
  margin-top: 40px;
  text-align: end;
}

.section1__navlog > tr > td > input:disabled,
.flight__section > tr > td > input:disabled,
.route__plan > tr > td > input:disabled {
  border-radius: 2px;
  border-color: #000;
  border-width: 1px;
  background-color: #fff;
}

.total > td {
  margin: 5px;
  border: 1px solid black;
}
.total > td > input {
  border: none;
}
.defoult {
  color: white;
}
.main__flight__section {
  margin-top: 10px;
  background-color: #f8f9fa;
}
