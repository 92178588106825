.sidebar {
  border: 0px 5px 5px 0px solid rgb(62, 56, 56);
  border-radius: 0px 5px 5px 0px;
  width: 200px;
  height: 100vh;
  background-color: #fdfeff;
  position: fixed;
  top: 90px;
  left: -200px;
  outline: #002d62 2px solid;
  transition: all 1s ease-in-out;
}
/* .sidebar:hover {
  box-shadow: 0px 0px 10px 0px rgba(24, 11, 211, 0.45) inset;
  outline: rgb(254, 254, 254) 2px solid;
} */

.sidebar.open {
  left: 0;
}

.sidebar-toggle {
  color: #010101;
  font-size: 24px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 100%; /* Adjust the value as per your design */
  transform: translateY(-50%);
  z-index: 999; /* Ensure the toggle button stays on top */
}

.sidebar.open .sidebar-toggle {
  left: 200px; /* Adjust the value to match the sidebar width */
}

.sidebar-toggle span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 20px;
}

.sidebar-menu li {
  margin-bottom: 10px;
}

.sidebar-menu a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
}

.sidebar-menu a:hover {
  color: #0c0b0b;
}

.color___fbar {
  /* margin-left: -2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdfeff;
  width: 30px;
  color: rgb(7, 7, 7);
  height: 70px;
  margin-bottom: 100px;
  border-radius: 0px 20px 20px 0px;
  /* outline: blue 2px solid; */
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-top-color: blue;
  border-right-color: blue;
  border-bottom-color: blue;
}
