.main__scanner {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.header__style__color {
  min-height: 40px;
  max-height: 100px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  padding: 4px;
}
/* .background__color___scanner {
    background-color: #f8f6ee;
  } */
.align__item__sec__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align__the__select {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select__number__main__scanner {
  width: 150px;
  height: 40px;
  font-size: small;
}
.align__header {
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: center;
}
.scanner__main__heading {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  width: 95%;
  align-items: center;
}
.main__scanner > h3 {
  margin: 20px;
  padding: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 130px;
  width: 300px;
  text-align: center;
  background-color: rgb(117, 207, 246);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 1s ease-in-out;
  cursor: pointer;
}
.main__scanner > h3:hover {
  background-color: rgb(23, 66, 84);
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.cargo__client {
  margin-left: 30px;
}
.trucks__detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}
/* <!-- HTML !-->
  <button class="button-30" role="button">Button 30</button> */

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #2a17a1;

  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  margin: 0px 0px;
}

.button-30:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
.truk__align {
  height: 53px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-color: #f5f5f9;
  padding: 5px;
  border-radius: 5px;
  color: black !important;
}
.truk__align:hover {
  height: 53px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-color: #f5f5f9;
  padding: 5px;
  border-radius: 5px;
  color: black;
}

.tr__for__main {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.tr__for__main:hover {
  z-index: 100;
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.tr__head__for__main {
  background-color: #2a17a1;
  color: white;
  border-radius: 15px;
  font-weight: 500;
}
.table__width__main {
  width: 95%;

  border-collapse: separate;
  border: solid black 1px;
  border-radius: 10px;
}
.section__main__new {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table__width__main td,
.table__width__main th {
  border-left: solid black 1px;
  border-top: solid black 1px;
}

.table__width__main th {
  background-color: blue;
  border-top: none;
}

.table__width__main td:first-child,
.table__width__main th:first-child {
  border-left: none;
}
.button__proceede__center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.animationn > h3 {
  position: relative;
}

.color__red__value {
  color: red;
}
.animationn > h3:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #111112;

  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.animationn > h3:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.space__headr__value {
  display: inline-block;
  width: 80px;
}
@media only screen and (max-width: 600px) {
  .section__main__new {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table__width__main td,
  .table__width__main th {
    border-left: solid black 1px;
    border-top: solid black 1px;
  }

  .table__width__main th {
    background-color: blue;
    border-top: none;
  }

  .table__width__main td:first-child,
  .table__width__main th:first-child {
    border-left: none;
  }
  .button-30 {
    align-items: center;
    appearance: none;
    background-color: #2a17a1;

    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 40px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    margin: 0px 0px;
  }
  .tr__for__main {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  .tr__for__main:hover {
    z-index: 100;
    cursor: pointer;
    transform: scale(1);
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
  .align__the__select {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  /* .select__number__main__scanner {
      width: 300px;
    } */
  .cargo__client {
    margin-left: 10px;
  }
}

/* 2 */

.table__test2 {
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
}

.blue {
  border: 2px solid #1abc9c;
}

.blue thead {
  background: #1abc9c;
}

.purple {
  border: 2px solid #9b59b6;
}

.purple thead {
  background: #9b59b6;
}

.table__test2 thead {
  color: white;
}

.table__test2 th,
.table__test2 td {
  padding: 5px 0;
  border-bottom: 1px solid gray;
  font-size: small;
}

tbody tr:nth-child(even) {
  background: #ecf0f1;
}

tbody tr:hover {
  background: #bdc3c7;
  color: #ffffff;
}

.fixed {
  top: 0;
  position: fixed;
  width: auto;
  display: none;
  border: none;
}

.up {
  cursor: pointer;
}
.button-302,
.button-303 {
  align-items: center;
  appearance: none;
  background: #002d62;

  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  margin: 0px 0px;
}
.tr__test2 {
  width: 50%;
}
thead tr td h5 {
  text-align: center;
}
/* 3 */
.table__test3 {
  border-radius: 5px;
  outline: 2px solid;
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}
/* 3 */
.table__test3 thead {
  border-radius: 5px;
  outline: 2px solid black;
  width: 97%;
  background: #002d62;

  color: white;
  font-weight: 500;
}
.to__center__main {
  /* min-height: 100vh; */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-self: center;
}

.to__align__text {
  text-align: left;
}
.fuel__rate__input {
  border: none;
  width: 70px !important;
  border: 1px solid;
  border-radius: 5px;
  text-align: right;
}
.fuel__rate__amount {
  border: none;
  width: 100px;
  border: 1px solid;
  border-radius: 5px;
  text-align: right;
}
.fuel__rate__input:focus {
  border: none;
  width: 100px;
  border-bottom: 1px solid;
}

.tr__test1 {
  height: 40px;
  font-size: small;
  font-weight: 500;
}
.fuel__rate > td {
  padding: 5px 0px;
  border-bottom: 1px solid grey;
  font-size: small;
  text-align: center;
}
.ribbon > h3 {
  color: white;
}
.order__align > p {
  text-align: right;
  margin-right: 10px;
}

.new__search {
  font-size: 16px;
  font-weight: 600;
}

.new__search__input {
  outline: none;
  border: 1px solid;
  margin: 10px 10px;
  width: 200px;
}
.span__icon {
  display: inline-block;
  margin: 5px;
  position: relative;
  left: -40px;
  top: 2px;
}
.currency__Select {
  border: none;
  border: 1px solid;
  border-radius: 5px;
  width: 70px;
}
.currency__Select {
  -webkit-appearance: auto;
  -moz-appearance: inherit;
  text-indent: initial;
  text-overflow: initial;
}

.currency__Select::-ms-expand {
  display: initial;
}
