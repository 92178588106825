.main__for__login {
  background-color: #ffffff;
}
.header {
  height: 100vh;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.techlogheading {
  background-color: #ffffff;
  margin: 20px;
  color: #17178c;
  font-family: Lucida Sans, Lucida Sans Regular, Lucida Grande,
    Lucida Sans Unicode, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 28px;
  text-shadow: 3px 3px 3px #ccd6dc;
}

/* .To_center_form {
  height: 90vh;

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
} */
.form {
  width: 100%;

  max-width: 420px;

  min-height: 400px;

  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;

  padding: 35px;

  background-color: rgb(255, 255, 255);

  border-radius: 10px;
  box-shadow: 7px 8px 15px -3px rgb(49, 24, 74);
  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
  border-left: 3px solid blue;
  border-top: 3px solid blue;
  animation-duration: 2s;
  animation: example;
}

.form_aliging_size {
  width: 300px;
}

.for_sign_in_heading {
  font-size: 25px;

  font-weight: normal;
}

/* .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.for_login_input[type="email"],
.for_login_input[type="text"],
.for_login_input[type="password"] {
  font-size: 16px;

  padding: 4px;

  border-radius: 5px;

  justify-content: center;

  align-items: center;
}
.for_login_input {
  display: block;
  height: 40px;
  margin: 10px;
  border-style: groove;
  border-width: 2px;
  border-color: #cec9c9;
  width: 280px;
  font-size: 16px;
  font-weight: 100;
  border-radius: 5px;
}

.for_lable_form {
  display: inline;
  font-weight: bold;
  font-size: 20px;
  margin-left: 16px;
}
/*  */

.to_center_pass_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*  */

.offscreen {
  position: absolute;

  font-size: 16pt;
  padding: 4px;
  margin-left: 23px;
  border-radius: 0.3rem;
  justify-content: center;
  align-items: center;
  border: none;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;

  margin-left: 0.25rem;
}

.line {
  display: inline-block;

  font-size: 14px;
}

.fpwd {
  display: flex;

  justify-content: center;

  align-items: center;
}
button {
  border-radius: 4px;
  min-width: 80px;
  height: 30px;
  background-color: #002d62;
  border: none;

  font-weight: 700;
  color: #fff;
  cursor: pointer;
  outline: 2px solid #002d62;
  transition: 0.21s;
}
button:hover {
  outline-offset: 3px;
  outline: 2px solid #002d62;
  background-color: #002d62;
}

.sign {
  background-color: #002d62;

  color: white;
  transition: 0.2s;
  margin: 25px 25px 25px 10px;
}
.sign:hover {
  background-color: #002d62;
}

.ptag {
  margin-top: 3px;
  font-size: 14px;
}

.pass {
  margin-top: 10px;

  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
  font-size: 14px;
}

.fpwd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ptag {
  margin-top: 3px;
  margin-left: 23px;
}
.pass {
  margin-top: 10px;

  padding: 100%;
}

.click {
  color: blue;

  text-decoration: underline;
}

span.skew-shake-x {
  animation: skew-x-shake 1.2s infinite;
  color: red;
  font-weight: 500;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  margin: 10px 10px 10px 10px;
}
.checkbox__show__password {
  display: flex;
  justify-content: end;
  align-items: center;
}
@keyframes skew-x-shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
.pic__align {
  height: 90vh;
  width: 100%;
  /* background-color: rgb(246, 250, 250); */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;

  align-items: center;
}

@keyframes example2 {
  0% {
    right: -150%;
    top: 0px;
  }
  100% {
    right: 0%;
    top: 0px;
  }
}
@keyframes example {
  0% {
    left: -100%;
    top: 0px;
  }
  100% {
    left: 0%;
    top: 0px;
  }
}
.pic__name {
  animation-duration: 2s;
  animation-name: example;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
/*  */
.fpo {
  position: relative;
  margin: 25% auto;
  display: block;
}
.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}
.block {
  display: block;
  width: 501px;
  height: 16px;
  color: black;
}

.block2 {
  width: 78px;
  height: 8px;
  margin-bottom: 8px;
}
.block3 {
  width: 131px;
  height: 8px;
  margin-bottom: 16px;
}
.circle__login {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  float: right;
}
#card {
  box-sizing: border-box;
  width: 595px;
  background: #fff;
  position: relative;

  top: 0%;
}
.card-image {
  box-sizing: border-box;
  display: block;
  width: 535px;
  height: 120px;
  background: #fafafa;
  padding: 16px;
}
.card-content {
  clear: both;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
}
#imagePreview {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
#imagePreview_2 {
  max-width: 100%;
  max-height: 300px; /* Adjust as needed */
  margin-top: 10px;
}
@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media only screen and (max-width: 920px) {
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    width: 340px;
    padding: 25px;
  }
  .pic__align {
    height: 120vh;
    display: flex;
    flex-direction: column;
  }

  .image__style {
    max-width: 100%;
    height: auto;
  }
  .To_center_form {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  #card {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    position: relative;

    top: 0%;
  }
  .block {
    display: block;
    width: 100%;
    height: 16px;
    color: black;
  }
  .card-image {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 120px;
    background: #fafafa;
    padding: 16px;
  }
  .for_login_input {
    width: 100%;
  }
  .techlogheading {
    margin: 20px 10px;
  }
}
