* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.app {
  color: var(--text-color);
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
* {
  font-family: "Mulish", sans-serif;
}

.custom-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  border-collapse: collapse;
}
.header-cell {
  border-radius: 4px;
}
.header-cell td {
  background-color: #43505f;
  color: white;

  border: 1px solid #055fc5;
}

.table-row {
  height: 15px;
  margin: 5px 0;
  padding: 10px;
  border-bottom: 0.3px solid #002d62; /* Add bottom border between rows */
}

.custom-table td {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 4px 16px 4px 40px;
}
.header-cell tr > td {
  color: white;
}
.custom-table > tbody > tr > td {
  background: white;
}
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  border-radius: 5px; /* Apply border-radius to the table */
}

.custom-table td,
.custom-table th {
  padding: 5px 10px;
  border-top: 1px solid gray;
}

.custom-table tr:first-child th {
  border-top: none;
}

.custom-table tr:last-child td {
  border-bottom: none;
}
.header-cell td {
  background-color: #43505f;
  color: white;

  border: 1px solid #055fc5;
}

.table-row {
  height: 32px;
  margin: 5px 0;
  padding: 10px;
  border-bottom: 0.3px solid #002d62; /* Add bottom border between rows */
}

.custom-table td {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.header-cell tr > td {
  color: white;
}
.custom-table > tbody > tr > td {
  background: white;
}
.custom-table > tbody > tr {
  color: black !important;
}
.custom-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  border-radius: 5px; /* Apply border-radius to the table */
}
.narration__all {
  max-width: 350px;
  min-width: 350px;
  max-height: 80px;
  min-height: 80px;
}
.overflow__y__ {
  overflow-y: auto !important;
}

@media only screen and (max-width: 750px) {
  input,
  input[type="date"] {
    margin: 0px !important;
  }
}
.newpages__input input,
.newpages__input input[type="date"] {
  margin: 0px !important;
}
.newpages__input button:hover {
  outline-offset: 0px;
  outline: none;
  background-color: #002d62;
}
textarea {
  border: 1px solid;
}
