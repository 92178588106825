body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
:root {
  --text-color: #fff;
  --bg-color: #002d62;
  --font--weight: 500;
}
input,
select {
  border: 1px solid;
}
/* === HEADING STYLE #3 === */
.header__of__main h1 {
  display: inline-block;
  font-family: "josefin sans";
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: var(--bg-color);
  text-transform: uppercase;
  margin-left: 15px;
}
.header__of__main h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: var(--bg-color);
}
.header__of__main h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;

  background-color: var(--bg-color);
}
@media only screen and (max-width: 720px) {
  .header__of__main h1 {
    font-size: 20px !important;
  }
}
