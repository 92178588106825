.header_main {
  margin: 10px;

  color: #002d62;
  font-size: larger;
}
.main__align_items {
  text-transform: uppercase;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
/* .image__main {
  position: relative;
} */

.image__main::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../Images/FlyImage.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.8; /* Adjust the opacity value to control the opacity */
  z-index: -1;
}
/* .image__main > img {
  height: 75vh;
  width: 80%;
} */
.defoult__link > p {
  width: 180px;
  height: 100px;
  margin: 20px;
  cursor: pointer;

  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002d62;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -0px rgb(0, 0, 0), 0px 7px 13px -8px rgb(0, 0, 0),
    0px -3px 0px -5px rgb(0, 0, 0) inset;
  padding: 0px 10px;
}
.defoult__link p:hover {
  width: 180px;
  height: 100px;
  margin: 20px;
  cursor: pointer;

  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #002d62;
  color: #fff;
  border-radius: 10px;
}

.defoult__link {
  text-decoration: none;
  color: white;
  /* text-decoration: underline; */
  text-align: center;
}
.defoult__link:active {
  color: rgb(255, 250, 250);
}
.hover-underline-animation {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #002d62;
  color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.for__more__data {
  font-size: 12px;
}
