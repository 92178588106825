.registration-form-container {
  max-width: 610px;
  margin: 0px auto;
  padding: 20px;
  border-radius: 7px;
  /* background-color: #f8f8f8; */
  background: #ecf1f2;
  /* border-style: ridge;s */
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  /* box-shadow: 0px 23px 18px rgb(218 231 241);  */
}
.h2 {
  text-align: center;
  margin-bottom: 20px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-weight: 500;
  margin-top: 20px;
}
.form-group {
  margin-bottom: 15px;
}
.label {
  display: block;
  padding: 4px;
  text-transform: lowercase;
}
.label:first-letter {
  text-transform: uppercase;
}
.expiry_date {
  width: 188px;
}
.navbar-container1 {
  display: flex;
  /* width: %; */
  height: 60px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #002d62;
  color: white;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px 0px rgba(24, 11, 211, 0.45) inset; */
  /* transition: all ease-in-out 1s; */
}
.port_pass {
  border: none;
  border-bottom: 1px solid;
}
.center__no__data {
  display: flex;
}
.label > input {
  border: none;
  border-bottom: 1px solid;
  height: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 210px;
  border-radius: 4px;
}
.Note {
  min-width: 240px;
  max-width: 240px;
  min-height: 103px;
  max-height: 103px;
}
.button[type="submit"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #8987e8;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.nxt {
  display: flex;
  justify-content: right;
  padding: -19px;
  gap: 20px;
}
.nxt1 {
  display: flex;
  justify-content: end;
  padding: -19px;
}
.Btn {
  align-items: center;
  background: #8d9598;
  border: 0 solid #e2e8f0;
  box-sizing: border-box;
  color: #171616;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 3px;
  justify-content: center;
  overflow-wrap: break-word;
  padding: 17px;
  text-decoration: none;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button[type="submit"]:hover {
  background-color: #513dc0;
}
.registration_employee {
  display: flex;
  justify-content: space-between;
}
.img {
  clip-path: circle(50%);
  height: 134px;
}
.profile_pic {
  width: 130px;
  height: 130px;
}
.large_image {
  height: 200px;
}
.file-upload {
  display: none;
}
.circle {
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 129px;
  height: 128px;
  border: 2px solid rgb(15 14 14 / 20%);
  position: absolute;
  position: relative;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
img {
  max-width: 100%;
  height: auto;
}
.p-image {
  position: absolute;
  top: 167px;
  left: 90px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
  font-size: 1.2em;
}
.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}
.Detials > div > input {
  border: 1px solid;
  width: 235px;
  border-radius: 4px;
  height: 28px;
}
.Detials > div {
  display: flex;
  padding: 8px;
}
.Detials > div > label {
  width: 200px;
}
.row {
  text-align: -webkit-center;
}
.Detials {
  /* margin-top: 10px; */
  margin: 10px 10px 10px 55px;
}
.Info {
  margin: 10px 10px 10px 55px;
}
.radio {
  width: 20px;
}
.radio_btn {
  display: flex;
  gap: 12px;
  width: 165px;
}
.radio_btn > div > input {
  height: 18px;
}
.radio_btn > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Info > div {
  display: flex;
  padding: 10px;
}
.input_field {
  border: 1px solid;
  width: 234px;
  border-radius: 4px;
  height: 28px;
}
.Info > div > .label {
  width: 208px;
}
.portpass {
  display: flex;
}
.portDetails {
  display: flex;
}
.portDetails > .label {
  width: 200px;
}
.Pass_port_no {
  display: flex;
  padding: 10px;
}
.Pass_port_no > label {
  width: 200px;
}
.Pass_port_no > input {
  border: 1px solid;
}
.upload_file_for_register > label {
  width: 200px;
}

.camera_btn {
  background-color: #6a6b6d;
  border: none;
  color: #fff;
  width: 36px;
  border-radius: 3px;
  font-size: 17px;
  height: 32px;
}
.camera_upload {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 15px;
}
.Proceed {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;

  cursor: pointer;

  font-size: 15px;

  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;

  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;

  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
}
.retake {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  color: #ffffff;
  cursor: pointer;

  font-size: 15px;

  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;

  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;

  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
}
.Capture {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  background-color: #002d62;
  color: white;
  cursor: pointer;

  font-size: 15px;

  font-weight: 500;
  height: 34px;
  line-height: 20px;
  list-style: none;

  padding: 8px 9px;
  position: relative;
  text-align: center;
  text-decoration: none;
  margin: 10px;
  box-shadow: 0px 0px 0 0, #000 1px 2px 0 0px;
}
.camera_btn2 {
  display: flex;
  gap: 20px;
}
.Pass_port_no .upload_file {
  width: 205px;
}
/* .Preview{
  background-color: #f7d8f7;
} */
.preview_btn {
  padding-left: 9px;
  /* border-radius: 8px; */
}
.form7 {
  padding: 10px;
}
.label_upload {
  text-align: center;
}
.upload_file {
  height: 30px;
  width: 100px;
  border: 1px solid;
}
.upload_file_for_register {
  display: flex;
  padding: 10px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 9px;
  border-radius: 8px;
  /* font-size: 20px; */
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.button {
  border-radius: 4px;
  /* min-width: 80px; */
  height: 30px;
  background-color: #002d62;
  border: none;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  padding: 3px;
  outline: 2px solid #002d62;
  transition: 0.21s;
}
.button :hover {
  outline-offset: 3px;
  outline: 2px solid #002d62;
  background-color: #002d62;
}
.camera_btn {
  background-color: #6a6b6d;
  border: none;
  color: #fff;
  width: 36px;
  border-radius: 3px;
  font-size: 17px;
  height: 32px;
}
.for__camera__btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .textarea {
    display: flex;
    flex-direction: column;
  }
  .registration-form-container {
    width: 100%;
    overflow-x: scroll;
    padding: 0px;
  }
  .form {
    padding: 20px;
  }
  .camera_upload {
    gap: 9px;
  }
  .input_field {
    border: none;
    width: 300px;
    border-radius: 4px;
  }
  .Detials > div > input {
    border: none;
    width: 300px;
    border-radius: 4px;
  }
  .Note {
    border: 0.5px solid;

    min-width: 205px;
    max-width: 205px;
    min-height: 103px;
    max-height: 103px;
  }
  .nxt {
    padding: 20px;
  }
  .width__video {
    width: 100%;
  }
}
