.techlog__header {
  margin: 5px;
  display: flex;
  justify-content: center;
}
.new__document__btn {
  margin-left: 20px;
}
.for__msg__save__button {
  margin: 0% 1% 1% 1%;
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.an__error__occured {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

.disabled__header__secton__top {
  border-radius: 2px;
  border-color: #000;
  border-width: 1px;
  background-color: #fff;
}
.main_table1_th,
.main_table1_td {
  border: 1px solid rgb(0, 0, 0);
  text-transform: uppercase;
}
.main_table1,
.to_align_engine_reading_table {
  width: 98%;
  margin: 10px;
  border: 2px solid black;
  text-transform: uppercase;
}
.main_table1 {
  width: 98%;

  justify-content: center;
  margin: 10px;
  border: 2px solid black;
  text-transform: uppercase;
}
.inside_table_input {
  width: 60px;
  margin: 0px;
  margin-left: 0px;
  text-align: center;
  height: 20px;
  border: none;
  color: #000;

  /* border-color: #000; */
  /* border-width: 1px; */
}
.inside_table_input__time {
  width: 100%;
  margin: 0px;
  text-align: center;
  height: 20px;
  border: none;
  color: #000;
}
.select__to__main__table {
  border-style: none;
  width: 120px;

  margin: 1px;
}
.select__to__main__table::-ms-expand {
  display: none;
}
.main_table1_th {
  background-color: #002d62 !important;
  -webkit-print-color-adjust: exact !important;
  color: rgb(0, 0, 0);
  font-weight: 500;
  color: #fff;
}
.main_table1_th,
.main_table1_td {
  height: 40px;
}
.defaoult__select__main:disabled {
  width: 120px;
}
.main_table1_td {
  background-color: #f8f9fa;
}
.button__to__add__new__row {
  font-weight: 500;
  font-size: larger;
  position: sticky;
  margin-left: 10px;
  margin-bottom: 5px;
}
.selct_input_section {
  width: 70px;
}
/* .to__ovrgjfkfk {
  margin: 10px 0px;
  width: 99%;
  position: sticky !important;
  left: 10px;
} */
.new__save__plus__save {
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input {
  height: 30px;
  margin: 0px 10px;
  width: 150px;
  color: #000;
  background-color: #fff;
}
select {
  width: 150px;

  height: 30px;
  margin: 1px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select::-ms-expand {
  display: none;
}
.for__defoult__margin {
  margin-left: 10px;
}
.hrs__flown__input {
  margin: 1px 10px;
}
label {
  display: block;
  width: 100%;
  text-align: left;
}
.table_for_1st_info {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
  min-height: 100px;
  font-size: small;
  width: 100%;
  background-color: #fff;
}
.heading_main_table {
  margin: 30px;
  text-transform: capitalize;
  position: sticky;
  left: 25px;
}
.reading_header {
  margin: 0px;
  text-transform: capitalize;
  position: sticky;
  left: 25px;
  width: 99%;
}
.main1 {
  min-height: 235px;
  background-color: #f8f9fa;
  font-size: small;
  overflow-x: scroll;
}
/* .main1 {
  min-height: 235px;
  background-color: #f8f9fa;
  font-size: small;
  overflow-x: scroll;
  border: 1px solid #ccc; Add a border for visual clarity 
} */

.for_nocolor_heading,
.for_redHeading,
.for_blueHeading {
  text-align: left;
  font-size: 14px;

  margin-left: 10px;
}
.main_table {
  width: 100%;
}
.test {
  pointer-events: none;
  border: none !important;
}

.pointer__event {
  pointer-events: none;
}
.new__save__plus__save {
  display: flex;
}
/* button {
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  background-color: rgb(21, 22, 54);
  border: none;

  font-weight: 600;
  color: white;
  cursor: pointer;
  outline: 3px solid rgb(37, 6, 46);
  transition: 0.21s;
}
button:hover {
  outline-offset: 4px;
  background-color: rgb(37, 6, 46);
} */
.for__doc__no__and__button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.aside__display__greater_or__less {
  margin-left: 1%;
  display: flex;
  min-height: 35px;
}

.button__for__search__current {
  max-width: 37px;
  font-weight: 700;
  cursor: pointer;
  height: 25px;
  width: 40px;
  background-color: #002d62 !important;
  -webkit-print-color-adjust: exact !important;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  margin: 0px 3px;
}
.row_1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
}
.row_2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  justify-content: space-around;
  align-items: center;
  width: 78%;
}

.all_input_below_main_table {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
}
.to__make__save__btn__stycky {
  display: flex;
  justify-content: end;
  position: sticky;
  left: 0;
}
.section_1_buttons {
  height: 150px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.for_end_section {
  background-color: #f8f9fa;

  height: 230px;
  display: flex;
}
.all_input_below_main_table {
  width: 100%;
  table-layout: auto;
}

/* .pilotdefect_button {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
} */

.forGreen_background {
  background: palegreen;
}
.for_redHeading {
  color: red;
}
.for_blueHeading {
  color: blue;
}

/* 2nd page for  */
.section_for2nd_page {
  margin: 1%;
  /* margin: 50px 20px 30px 20px; */
  padding: 20px 10px;
  border-width: 0.5px;
  border-color: rgb(215, 221, 227);
  border-style: inset;
  box-shadow: 4px 0px 4px 4px rgba(0, 0, 0, 0.2);
}
.heading_for_head2nd_page {
  color: red;
}

.section5 {
  width: 100%;
  /* overflow-x: auto; */
}
/* for last page table */
.main_table2,
.main_table1_th2,
.main_table1_td2 {
  margin: 20px;
  border: 1px solid black;
}
.main_table1_th2,
.main_table1_td2 {
  height: 40px;
}
.inside_table_input2 {
  width: 78px;
}
.defoult__stle__remove {
  border-color: #000;
  color: #000;
}
.main_table1_th2 {
  background-color: #002d62 !important;
  -webkit-print-color-adjust: exact !important;
  color: rgb(0, 0, 0);
  font-weight: 500;
  color: #fff;
}
.link__defolt {
  text-decoration: none;
}
.Leg_to_sticky {
  position: sticky;
  left: 0;
  z-index: 1;
  border-style: groove;

  border-width: 2px;
}

.for_save_button {
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.footer_dashbord_style {
  margin-top: 100px;
}
.main_table1_tr2 {
  text-transform: uppercase;
}

.button__save__edit {
  display: flex;
  width: 100%;
  justify-content: end;
}
.save__edit__button {
  margin: 10px 20px;
}
/* for defoult bos handling */
.for_default_box_top {
  border: none;
  color: #f8f9fa;
  background-color: inherit;
  outline: 0px;
}
.for_default_box {
  color: white;
  border: none;
  background: inherit;
  width: 145px;
}
.for_certificate_relese_aside {
  display: flex;
  flex-wrap: wrap;
}
.indicate__pilot__defect {
  margin: 0% 0%;
}
.Indicate_Pilot_Defects {
  margin-left: 1%;
}

.for__default__text1 {
  color: #fff;
  width: 170px;
}
.for__default__text2 {
  width: 170px;
}
.for__default__text3 {
  width: 170px;
}
.main__table2__lable {
  margin-left: 10px;
  font-weight: 600;
}
/* input:disabled {
  border-style: groove;
  border-color: black;
  border: 4px;
  border-width: 5px;
  color: #000;
} */

/* input[type="text"]:disabled {
  color: #000;
  border-radius: 2px;
  border-color: #000;
  border-width: 1px;
} */
.certificate__of_dew > div > th {
  margin: 5px 0px;
  width: 250px;
}
.lh_rh {
  text-align: center;
}
.save__confirm__top {
  min-width: 80px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  border: none;

  font-weight: 400;
  color: rgb(0, 0, 0);
  cursor: pointer;

  transition: 0.21s;
  outline: none;
  border-bottom-style: solid;
  border-radius: 0px;
  margin: 0px 5px;
}
.save__confirm__top:hover {
  outline-offset: 3px;
  background-color: rgb(230, 227, 227);
}
.certificate__of_dew > div > td > input {
  margin: 3px 0px;
}
.defoult__stle__remove {
  font-weight: 600;
  padding: 0px 0px 0px 5px;
}
.arrow__and__header {
  display: flex;
  justify-content: space-between;
}
.arrow__go__end {
  display: flex;
  justify-content: space-between;
}
.arrow__go__end > p {
  margin: 0px 5px;
  cursor: pointer;
  font-size: 26px;
}
.scroll-container {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  border: 1px solid #ccc;
  background: #f0f0f0;
  scroll-behavior: smooth; /* Add smooth scrolling effect */
  transition: transform 0.2s ease; /* Add transition for smooth scrolling */
}

.arrow__go__end p {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .display__none__style {
    display: none;
  }
  .table_for_1st_info {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .leg_for_mobile_app {
    width: 70px;
  }
  .inside_main_div > tr > th,
  .inside_main > tr > th {
    width: 110px;
  }
  .inside_main_div > tr > td > input,
  .inside_main_div > tr > td > select,
  .inside_main > tr > td > input {
    margin: 5px 0px;
  }
  .disabled__header__secton__top {
    margin: 0px;
  }
  .all_input_below_main_table {
    margin: 10px 0px;
  }
  .main1,
  .main__overflow,
  .all_input_below_main_table,
  .for_end_section,
  .for_end_section > .section5 {
    overflow-x: scroll;
  }
  .certificate__of_dew > div > th {
    margin: 5px 0px;
    width: 140px;
  }
  .select__to__main__table {
    border-style: none;
    width: 100px;
    margin: 1px;
  }
  .for_default_box {
    background: inherit;
    border: none;
    color: #fff;
    width: 150px;
  }
}

@media print {
  /* hide all buttons */
  .for__msg__save__button {
    display: flex;
    justify-content: center;
  }
  button {
    display: none;
  }
  .button__for__search__current {
    display: none;
  }
}

input[type="date"],
.for__defoult__margin,
.newSelect {
  border: #000;
  outline: black;
  border-style: groove;
  border-width: 1px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
  margin: 0px 0px 2px 10px;
}
.newSelect {
  margin: 0px 0px 2px 10px;
}
input[type="date"]:disabled {
  border: #000;
  outline: black;
  border-style: groove;
  border-width: 1px;
  border-radius: 2px;
  color: #000;
  font-weight: 600;
}
.aside_btn {
  width: 100%;

  display: flex;
  justify-content: end;
}
.aside__button__code {
  margin-right: 20px;
}
.for__msg__save__button > aside > button {
  margin: 0px 10px;
}
@media only screen and (min-width: 1400px) {
  .inside_table_input2,
  .main_table1_td2 > button {
    width: 78px;
    position: relative;
    top: 40%;
    left: 40%;
    transform: translate(-40%, -40%);
  }
}
