.heading__engine {
  margin-left: 10px;
}
.ALign__engineMaster {
  background-color: rgb(244, 249, 252);
  padding: 10px 5px;
  display: flex;
  align-items: center;
  margin: 10px 5px;
  gap: 2px;
  border-radius: 5px;
}
.ALign__engineMaster input {
  border: 1px solid;
  border-radius: 5px;
  padding-left: 5px;
}
.ALign__engineMaster > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ALign__engineMaster > div > p {
  margin-left: 10px;
}
.table__master__app {
  padding: 10px;
  width: 50%;
}
.search__option {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0px 10px;
}
.search__option input {
  border: 1px solid;
  border-radius: 5px;
  width: 250px;
  padding-left: 5px;
}
.select__class {
  width: 110px;
}
@media only screen and (max-width: 720px) {
  .ALign__engineMaster,
  .search__option {
    display: block;
  }
  .ALign__engineMaster > div {
    display: block;
  }
  .select__class {
    padding-left: 10px;
  }
  .ALign__engineMaster button {
    margin: 10px;
  }
  .table__master__app {
    width: 100%;
  }
}
