.centernav {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-container {
  display: flex;
  width: 98%;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #002d62;
  color: white;
  border-radius: 5px;
  transition: all ease-in-out 1s;
  z-index: 9999;
}

.navbar-container:hover {
  box-shadow: 0px 0px 10px -5px rgba(24, 11, 211, 0.45) inset;
  outline: #fff 2px solid;
}

.navbar-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none; /* Hide by default */
}

.navbar-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-menu {
  display: flex; /* Display flex by default */
  list-style: none;
  width: 35%;
}

.nav-menu.active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background-color: #002d62;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.hover-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.hover-dropdown:hover .dropdown-list {
  display: block;
}

.dropdown-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  background-color: #002d62;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  font-size: 15px;
}

.dropdown-list li {
  padding: 8px 16px;
  list-style: none;
  cursor: pointer;
  width: 260px;
}

.dropdown-list li:hover {
  background-color: #002d62;
}

.items__drop__down {
  padding: 5px;
  border-radius: 5px;
  line-height: 20px;
  background-color: #002d62;
}

.menu_rights {
  display: flex;
  gap: 10px;
  width: auto;
  position: absolute;
  top: -10px;
}
.menu__heading__size {
  width: 250px;
}
.logged_in_user {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 10px;
}
.small__screen {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-icon {
    display: block; /* Show on small screens */
  }

  .nav-menu {
    display: none; /* Hide by default */
  }

  .nav-menu.active {
    display: flex;
    margin: 0px 1%;
    width: 98%;
  }
  .navbar-links {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: #002d62;
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 1s ease-in;
  }

  .navbar-links.active {
    top: 55px;
  }

  .navbar-links li {
    margin-bottom: 1rem;
  }

  .menu_rights {
    display: block;

    position: relative;
    top: auto;
  }
  .menu__heading__size {
    width: 150px;
  }
  .small__screen {
    display: flex;
    gap: 5px;
  }
  .big__screen {
    display: none;
  }
  .logged_in_user {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}
