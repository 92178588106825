.aside__back__button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button__fuel {
  margin: 10px 20px 10px 0px;
  background: #002d62 !important;
  color: #fff;
  font-weight: 600;
}

.link__defoult {
  float: right;

  text-decoration: none;
  margin-bottom: 10px;
}

/* .Fuel__expence__table > tr > th {
  text-align: left;
} */
.for__mobile {
  display: flex;
}
.Fuel__expence__table > tr > aside > div > td > select,
.date__fuel {
  margin: 10px 10px 0px 10px;
}
.Fuel__expence__table > tr > aside > div > td > {
  margin: 10px 00px;
}
.Fuel__expence__table > tr > aside > div > td {
  min-width: 130px;
}
.Fuel__expence__table > tr > td > input {
  border-width: 1px;
  border-radius: 3px;
  margin: 5px 10px 5px 10px;
}

.content {
  background: #ecf1f2;
  width: 98%;

  min-height: 3.75em;
  margin: 1em auto;
  padding: 1.25em;
  border-radius: 0.313em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  line-height: 1.5em;
  color: #292929;
}
.fuel_rate {
  border-width: 1px;
  border-radius: 3px;
  margin: 5px 10px 5px 10px;
}
.ribbon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 0.5em;
  font-size: 1.5em;
  margin: 0 0 15px -0.625em;
  line-height: 1.575em;
  color: #e4f1f5;
  border-radius: 0 0.156em 0.156em 0;
  background: #002d62;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
}
.ribbon > div > h3 {
  color: #fff;
}

.ribbon:before,
.ribbon:after {
  position: absolute;
  content: "";
  display: block;
}

.ribbon:before {
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
}

.ribbon:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}
.for__mobile_button {
  display: flex;
}
.select__width1 {
  width: 450px;
  margin-left: 10px;

  margin: 5px 10px 5px 10px;
}
.select__width1 {
  -webkit-appearance: auto;
  -moz-appearance: initial;
  text-indent: initial;
  text-overflow: initial;
}

.select__width1::-ms-expand {
  display: initial;
}
.select__width_new,
.select__width__approvrd {
  -webkit-appearance: auto;
  -moz-appearance: inherit;
  text-indent: initial;
  text-overflow: initial;
}

.select__width_new::-ms-expand {
  display: initial;
}

.select__width_new {
  margin: 5px 10px 5px 10px;
}

.first__tr__filter {
  background: #002d62;
  line-height: 1.5;
  color: #fff;
  border-radius: 10px;
  height: 40px;
}
.new__filter {
  height: 50px;
  outline: none;
  border: none;
}

.Fuel__expence__table > tr > td > input[type="date"] {
  border: #000;
  outline: black;
  border-style: groove;
  border-width: 1px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
  width: 110px;
  /* margin: 17px 10px 2px 0px; */
}
.search__width {
  width: 200px;
}
.List__of__fuel {
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;
}
.button__fuel_back {
  background: #002d62 !important;
  color: white;
  font-weight: 600;
  margin: 0px 10px;
  outline: 2px solid white;
}
.button__fuel_back:hover {
  background: #002d62 !important;
  color: white;
  font-weight: 600;
  margin: 0px 10px;
  outline: 2px solid white;
}
.button__older__doc {
  border: none;
  outline: none;
  min-width: 100px;
  padding: 0px;
  margin: 5px;
  background: #002d62;
  color: #fff;
  font-weight: 300;
  transform: 2s;
}
.button__older__doc:hover {
  border: none;
  outline: none;
  background-color: none;
  text-decoration: underline;
  background-color: #ecf1f2;
  font-weight: 500;
  min-width: 100px;
  padding: 0px;
  margin: 5px;
  background: #002d62;
}

.filter__table > tr > td {
  border-bottom: 1px solid gray;
  text-transform: uppercase;
}
.filter__table {
  width: 100%;
  font-size: small;
  border-collapse: collapse;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 10px;
  outline: 2px solid;
  background-color: #fff;
}

.filter__table > tr > td > p {
  font-weight: 500;

  font-size: small;
  padding: 0px 2px;
  text-transform: uppercase;
  text-align: center;
}

.filter__table > tr > td {
  /* line-height: 01; */
  /* min-width: 45px; */
}
.filter__table > tr > .supplier_name__table {
  text-align: center;
  /* line-height: 01; */
  /* min-width: 50px; */

  max-width: 140px;
  padding-left: 5px;
}
.filter__table > tr > .location_name__table {
  text-align: center;
  /* line-height: 01; */
  /* min-width: 50px; */

  min-width: 150px;
  max-width: 200px;
  padding-left: 5px;
}
.location_aircraft {
  text-align: center;
}
.date__fuel__filter {
  text-align: center;
  width: 105px;
}
.for_space {
  width: 220px;
}
.span__icon__fuel__Expense {
  display: inline-block;
  margin: 5px;
  position: relative;
  left: -40px;
  top: 2px;
}

.defoult__filter {
  text-transform: uppercase;
}
.filter_table_old_doc > tr > div > aside > td > p > input {
  border: 1px solid;
}
.for__mobile > div > td > input[type="date"] {
  margin: 10px 10px;
  width: 110px;
}
.for__mobile > div > td > input {
  border: 1px solid black;
}
.filter_table_old_doc > tr > div > aside > td > p {
  width: 99px;
}
.filter_table_old_doc > tr > div > aside {
  min-width: 300px;
}
.older__align {
  display: flex;
  width: 100%;
}
.Fuel__expence__table input[type="date"] {
  width: 50px;
}
.main__2__table {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.number__align {
  text-align: right;
  padding-right: 10px;
}
@media (max-width: 600px) {
  .overflow__control {
    width: 100%;
    overflow-x: scroll;
  }
  body {
    font-size: 0.875em;
  }

  .ribbon {
    line-height: 1.143em;
    padding: 0.5em;
  }

  .ribbon:before,
  .ribbon:after {
    font-size: 0.714em;
  }
  .for__mobile {
    display: block;
  }
  .content {
    width: 90%;
  }
  .Fuel__expence__table > tr > aside > div > td {
    width: 120px;
  }
  .Fuel__expence__table > tr > aside > div > td > input {
    margin: 10px 10px 0px 10px;
  }
  .for__mobile_button {
    display: flex;
  }
  .button__fuel {
    margin: 10px 5px;
  }
  .first__tr__filter {
    background: rgb(10, 33, 59);
    border-radius: 10px;
    border-top: none;
  }

  .older__align {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.for__icon__gt__Lt {
  display: flex;
}
.Fuel__expense__redirect {
  font-size: 16px;

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(71, 71, 71);
  margin: 10px;
  font-weight: 700;
  cursor: pointer;
  transition: all ease-in-out 1s;
  border-radius: 5px;
}
.Fuel__expense__redirect:hover {
  background: rgb(68, 155, 255);
}
.view__btn {
  min-width: 50px;
  padding: 0px;
  margin: 5px;
  background: #002d62;
  color: #fff;
}
/* button {
  border-radius: 4px;
  min-width: 80px;
  height: 30px;
  background-color: rgb(171, 240, 255);
  border: none;

  font-weight: 700;
  color: rgb(0, 0, 0);
  cursor: pointer;
  outline: 2px solid rgb(54, 54, 54);
  transition: 0.21s;
} */
.view__btn:hover {
  outline-offset: 1px;
  outline: 2px solid rgb(54, 54, 54);
  background: #002d62;
  color: #fff;
}
.show__btn {
  text-align: right;
  padding: 0.5%;
  margin: 1%;
}
.show__more {
  display: flex;
  justify-content: flex-end;
}
