.toalignaside {
  display: flex;
  width: 100%;
}
.footerstyle {
  background: rgb(226, 231, 232);
  margin-top: 20vh;
}

.aside1 {
  margin: 10px;
  width: 100%;
}
.aside2 {
  margin: 10px;
  width: 100%;
}

.btnsfooter {
  width: 100px;
  background: rgb(51, 49, 167);
  color: white;
  padding: 14px 10px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  margin-left: 25px;
  cursor: pointer;
  transition: 1.3s;
}
.forbtns {
  display: flex;
  justify-content: space-around;
}
.btnsfooter:hover {
  background: rgb(5, 4, 84);
}
.text-center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}
@media only screen and (max-width: 600px) {
  .toalignaside {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
